<template>
  <div
    @click="$emit('click', $event)"
    @contextmenu.prevent="$emit('contextmenu', $event)"
    @mouseover="mouseover = true"
    @mouseleave="mouseover = false"
    :class="{
      'event-item': true,
      dark: $vuetify.theme.dark,
      selected,
      mouseover: hover && mouseover,
      fire: eventType === 2,
      'non-fire': eventType === 3,
      unknown: eventType === 4,
      planned: eventType === 5,
    }"
  >
    <div v-if="eventType === 2">Fire</div>
    <div v-else-if="eventType === 3">Non-Fire</div>
    <div v-else-if="eventType === 4">Unknown</div>
    <div v-else-if="eventType === 5">Planned Burn</div>
  </div>
</template>

<script>
import Theme from '@/mixin/theme.mixin'

export default {
  mixins: [Theme],
  props: {
    /**
     * emits
     * click
     * contextmenu
     */
    selected: {
      type: Boolean,
      default: false,
    },
    eventType: {
      type: Number,
      default: 1,
    },
    hover: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mouseover: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.event-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 40px;
  font-weight: 400;
  font-size: 16px;
  box-sizing: border-box;
  border-radius: 8px;
  color: var(--f-text-black-middle);
  border: 1px solid rgba(0, 0, 0, 0.26);
  &.dark {
    color: var(--f-text-white-middle);
    border: 1px solid rgba(255, 255, 255, 0.6);
  }

  &.selected,
  &.mouseover {
    font-weight: 500;
    border-width: 0;
    color: var(--f-text-white);
    &.dark {
      color: var(--f-text-white-high);
    }

    &.fire {
      background-color: var(--f-primary-90);
    }

    &.non-fire {
      background-color: var(--f-supplementary-green);
    }

    &.unknown {
      background-color: var(--f-supplementary-blue);
    }

    &.planned {
      background-color: var(--f-supplementary-purple);
    }
  }
}
</style>
