<template>
  <v-dialog v-model="value" max-width="400px" persistent no-click-animation>
    <v-card :class="`save-label-dialog ${themeName}`">
      <v-card-text class="pa-0">
        <div :class="`save-label-dialog-title ${themeName}`">Edit Event Type</div>
        <div :class="`save-label-dialog-content ${themeName}`">
          <div>{{ `[${logDate}]` }}<br />{{ cameraName }}</div>
        </div>
        <div class="event-container">
          <div class="event-container-row">
            <EventItem @click="onClickEventType(2)" :selected="eventTypeId === 2" :eventType="2" style="margin-right: 10px"></EventItem>
            <EventItem @click="onClickEventType(3)" :selected="eventTypeId === 3" :eventType="3"></EventItem>
          </div>
          <div class="event-container-row">
            <EventItem @click="onClickEventType(4)" :selected="eventTypeId === 4" :eventType="4" style="margin-right: 10px"></EventItem>
            <EventItem @click="onClickEventType(5)" :selected="eventTypeId === 5" :eventType="5"></EventItem>
          </div>
        </div>
        <div class="d-flex justify-end">
          <button @click="hide" :class="`save-label-dialog-button cancel ${themeName}`">Cancel</button>
          <button @click="ok" :class="`save-label-dialog-button ok ${themeName}`">Save</button>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import EventItem from '@components/common/EventItem'
import Util from '@/util'
import Theme from '@/mixin/theme.mixin'

export default {
  mixins: [Theme],
  components: { EventItem },
  props: {
    value: Boolean,
    /**
     * emit events
     * ok
     */
    data: Object,
  },
  data() {
    return {
      eventTypeId: 1,
    }
  },
  watch: {
    value(val) {
      if (val) {
        this.eventTypeId = this.data.event_type_id
      }
    },
  },
  computed: {
    ...mapState(['camMap']),
    logDate() {
      if (this.data) {
        return Util.getOffsetDate(this.data.created_at, this.data.offset)
      }
      return ''
    },
    cameraName() {
      if (this.data) {
        const cam = this.camMap[this.data.camera_id]
        return cam && cam.name
      }
      return ''
    },
  },
  methods: {
    hide() {
      this.$emit('input', false)
    },
    ok() {
      this.$emit('ok', this.eventTypeId)
      this.hide()
    },
    onClickEventType(eventTypeId) {
      if (this.eventTypeId === eventTypeId) {
        this.eventTypeId = 1
      } else {
        this.eventTypeId = eventTypeId
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.save-label-dialog {
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
  background-color: var(--f-text-white);
  &.dark {
    background-color: var(--f-bg-middle);
  }
}

.save-label-dialog-title {
  text-align: center;
  padding: 50px 0 20px;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: var(--f-text-black);
  &.dark {
    color: var(--f-text-white-high);
  }
}

.save-label-dialog-content {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 30px;
  color: var(--f-text-black);
  &.dark {
    color: var(--f-text-white-high);
  }
}

.event-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 61px;
  padding: 0 65px;

  .event-container-row {
    display: flex;

    &:first-child {
      margin-bottom: 12px;
    }
  }
}

.save-label-dialog-button {
  font-weight: 600;
  font-size: 16px;
  height: 60px;

  &.cancel {
    width: 160px;
    color: var(--f-text-black);
    background-color: var(--f-cool-gray-1);
    &.dark {
      color: #ffffffde;
      background-color: var(--f-supplementary-modal-cancel);
    }
  }

  &.ok {
    flex-grow: 1;
    background-color: var(--f-supplementary-modal-blue);
    color: var(--f-text-white);
    &.dark {
      color: var(--f-text-white-high);
    }
  }
}
</style>
