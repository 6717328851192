<template>
  <div class="frame-bar">
    <CircleButton :playerState="playerState" @play="onClickPlay" @pause="$emit('pause')" />
    <div class="timeline">
      <div class="progress-cover" v-if="!this.selectMode" :style="progressWdith"></div>
      <div
        v-for="(image, index) in loadedImages"
        :key="index"
        :class="{
          now: index === currentIndex && isLoaded && playerState !== CONSTANT.STOP_STATE && !selectMode,
          'first-index': index === 0,
          'last-index': loadedImages.length === index + 1,
          'selected-frame': selectMode && currentIndex === index,
        }"
        data-testid="Frame_Bar"
        :style="frameStyle(image, index)"
        @click="moveFrame(index)"
      >
        <img
          v-if="isLoaded && index === detectedImageIndex && $vuetify.theme.dark"
          src="@/assets/Caret-Up_Filled_16px_Icon.svg"
          width="14px"
          :class="getCaretClass('up')"
          style="opacity: 1"
        />
        <img
          v-if="isLoaded && index === detectedImageIndex && $vuetify.theme.dark"
          src="@/assets/Caret-Down_Filled_16px_Icon.svg"
          width="14px"
          :class="getCaretClass('down')"
          style="opacity: 1"
        />
        <img
          v-if="isLoaded && index === detectedImageIndex && !$vuetify.theme.dark"
          src="@/assets/Caret-Up_Filled_12px_Icon_Light_Mode.svg"
          width="14px"
          :class="getCaretClass('up')"
          style="opacity: 1"
        />
        <img
          v-if="isLoaded && index === detectedImageIndex && !$vuetify.theme.dark"
          src="@/assets/Caret-Down_Filled_12px_Icon_Light_Mode.svg"
          width="14px"
          :class="getCaretClass('down')"
          style="opacity: 1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CONSTANT from '@common/newImage/canvas/constant'
import CircleButton from '@common/newImage/CircleButton'

export default {
  components: { CircleButton },
  props: {
    detectedImageIndex: {
      type: Number,
      default: -1,
    },
    imageUrlList: {
      type: Object,
      default: [],
    },
    currentIndex: {
      type: Number,
      default: -1,
    },
    hasAfterImages: {
      type: Boolean,
      default: false,
    },
    playerState: {
      type: String,
      default: CONSTANT.PAUSE_STATE,
    },
    currentLogId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      defaultImages: (() => {
        const defaultImage = require('@/assets/playerbar-default-image.jpg')
        return Array(20).fill(defaultImage)
      })(),
      CONSTANT,
      ARROW_LEFT: 37,
      ARROW_RIGHT: 39,
      SPACEBAR: 32,
      selectMode: false,
    }
  },
  mounted() {
    window.addEventListener('keydown', this.onKeyDown)
  },
  destroyed() {
    window.removeEventListener('keydown', this.onKeyDown)
  },
  watch: {
    currentLogId(newLogId, prevLogId) {
      if (newLogId !== prevLogId) this.selectMode = false
    },
  },
  computed: {
    progressWdith() {
      if (this.playerState !== CONSTANT.STOP_STATE && this.isLoaded) {
        return {
          transform: `scaleX(${this.imageWidth * (this.currentIndex + 1)}%)`,
        }
      }
    },
    isLoaded() {
      return this.imageUrlList.length
    },
    imageWidth() {
      if (this.loadedImages.length) return 100 / this.loadedImages.length
      else return 0
    },
    loadedImages() {
      if (this.imageUrlList.length) {
        if (!this.hasAfterImages) return this.imageUrlList.concat(this.defaultImages)
        return this.imageUrlList
      } else {
        return this.defaultImages
      }
    },
  },
  methods: {
    onClickPlay() {
      if (this.selectMode) this.selectMode = false
      this.$emit('play')
    },
    moveFrame(frameIndex) {
      if (frameIndex >= 0 && frameIndex <= this.imageUrlList?.length) {
        if (!this.selectMode) this.selectMode = true
        this.$emit('moveFrame', frameIndex)
      }
    },
    onKeyDown(e) {
      if (e.keyCode === this.SPACEBAR) {
        e.preventDefault()
        this.playerPlayBySpaceBar()
      } else if (this.isLoaded && this.playerState === CONSTANT.PAUSE_STATE) {
        if (e.keyCode === this.ARROW_LEFT) {
          if (this.currentIndex > 0) {
            this.moveFrame(this.currentIndex - 1)
          }
        } else if (e.keyCode === this.ARROW_RIGHT) {
          if (this.currentIndex < this.imageUrlList.length - 1) {
            this.moveFrame(this.currentIndex + 1)
          }
        }
      }
    },
    playerPlayBySpaceBar() {
      if (this.playerState === CONSTANT.PLAY_STATE) this.$emit('pause')
      else this.onClickPlay()
    },
    getCaretClass(direction) {
      let className = `caret-${direction}-align`
      if (this.monitor) {
        className = className.concat('-monitor')
      }
      return className
    },
    frameStyle(image, index) {
      if (index === this.detectedImageIndex) {
        return {
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          height: '100%',
          width: `${this.imageWidth}%`,
          display: 'flex',
          justifyContent: 'center',
          opacity: this.$vuetify.theme.dark ? '0.7' : '0.45',
          border: '2px solid red',
          padding: '1px 0',
          boxSizing: 'border-box',
          backgroundClip: 'content-box',
        }
      }
      return {
        backgroundImage: `url(${image})`,
        backgroundSize: 'cover',
        height: '100%',
        width: `${this.imageWidth}%`,
        justifyContent: 'center',
        opacity: this.$vuetify.theme.dark ? '0.7' : '0.45',
        display: 'flex',
        padding: '1px 0',
        boxSizing: 'border-box',
        backgroundClip: 'content-box',
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.frame-bar {
  width: 100%;
  height: 36px;
  display: flex;
  margin: 20px 0;
  position: relative;
}

.timeline {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
}
.progress-cover {
  position: absolute;
  opacity: 0.7;
  padding: 1px 0;
  height: 34px;
  width: 100%;
  transform-origin: left;
  background: linear-gradient(to right, rgba(0, 255, 255, 1), rgba(245, 235, 53, 1));
}

.selected-frame {
  border: 3px solid;
  border-image: linear-gradient(to right, rgba(0, 255, 255, 1), rgba(245, 235, 53, 1));
  border-image-slice: 1;
}

.now {
  border-right: 3px solid rgba(245, 235, 53, 1) !important;
}

.last-index {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.first-index {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.caret-up-align {
  position: absolute;
  top: 36px;
}

.caret-down-align {
  position: absolute;
  bottom: 36px;
}

.caret-up-align-monitor {
  position: absolute;
  top: 62px;
}

.caret-down-align-monitor {
  position: absolute;
  bottom: 62px;
}
</style>
