<template>
  <v-dialog v-model="value" max-width="400px" @keydown.esc="hide" persistent>
    <v-card :class="`goto-label-dialog ${themeName}`" data-testid="Goto_modal">
      <v-card-text class="pa-0">
        <div :class="`goto-label-dialog-title ${themeName}`">Go to</div>
        <div :class="`goto-label-dialog-content ${themeName}`">
          <div
            @click="onClickGoto(0)"
            :class="`goto-label-container ${themeName}`"
            style="margin-bottom: 15px"
            data-testid="Goto_Public_Large_Button"
          >
            <img src="@/assets/Public_Filled_24px_Icon.svg" class="icon" />
            <div class="text-label">Camera Public Page</div>
            <img v-if="$vuetify.theme.dark" src="@/assets/Chevron-Right_Circle_24px_2_Icon.svg" class="icon-arrow" />
            <img v-else src="@/assets/Chevron-Right_Circle_24px_2_Icon-red.svg" class="icon-arrow" />
          </div>
          <div @click="onClickGoto(1)" :class="`goto-label-container ${themeName}`" style="margin-bottom: 15px" data-testid="Goto_Link1_Large_Button">
            <img src="@/assets/Link_Line_2_24px_Icon.svg" class="icon" />
            <div class="text-label">Camera Access Link 1</div>
            <img v-if="$vuetify.theme.dark" src="@/assets/Chevron-Right_Circle_24px_2_Icon.svg" class="icon-arrow" />
            <img v-else src="@/assets/Chevron-Right_Circle_24px_2_Icon-red.svg" class="icon-arrow" />
          </div>
          <div @click="onClickGoto(2)" :class="`goto-label-container ${themeName}`" style="margin-bottom: 49px" data-testid="Goto_Link2_Large_Button">
            <img src="@/assets/Link_Line_2_24px_Icon.svg" class="icon" />
            <div class="text-label">Camera Access Link 2</div>
            <img v-if="$vuetify.theme.dark" src="@/assets/Chevron-Right_Circle_24px_2_Icon.svg" class="icon-arrow" />
            <img v-else src="@/assets/Chevron-Right_Circle_24px_2_Icon-red.svg" class="icon-arrow" />
          </div>
        </div>
        <div :class="`goto-label-dialog-button ${themeName}`" @click="hide" data-testid="Cancel_Large_Button">Cancel</div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import util from '@/util'
import Theme from '@/mixin/theme.mixin'

export default {
  mixins: [Theme],
  props: {
    value: Boolean,
    urls: {
      type: Array,
      default: [],
    },
  },
  methods: {
    onClickGoto(index) {
      this.$log.debug('GotoDialog#onClickGoto', index, this.urls)
      if (this.urls[index]) {
        util.openUrl(this.urls[index])
        this.hide()
      }
    },
    hide() {
      this.$emit('input', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.goto-label-dialog {
  border-radius: 16px;
  background-color: var(--f-text-white);
  &.dark {
    background-color: var(--f-bg-middle);
  }
}
.goto-label-dialog-title {
  padding: 50px 0 30px;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: var(--f-text-black);
  &.dark {
    color: var(--f-text-white-high);
  }
}

.goto-label-dialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  padding: 0 16px;
  color: var(--f-text-black);
  &.dark {
    color: var(--f-text-white-high);
  }

  .icon {
    width: 24px;
    margin-left: 16px;
    margin-right: 10px;
  }

  .text-label {
    flex-grow: 1;
  }

  .icon-arrow {
    width: 24px;
    margin-right: 16px;
  }
}
.goto-label-container {
  display: flex;
  align-items: center;
  width: 320px;
  height: 50px;
  border-radius: 10px;
  cursor: pointer;
  background: var(--f-supplementary-highlight);
  &.dark {
    background: var(--f-bg-high);
  }
}

.goto-label-dialog-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  color: var(--f-text-black);
  background-color: var(--f-cool-gray-1);
  &.dark {
    color: var(--f-text-white-high);
    background-color: var(--f-supplementary-modal-cancel);
  }
}
</style>
