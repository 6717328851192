<template>
  <div style="display: flex; flex-direction: column">
    <transition name="legend-fade">
      <section v-if="legendExpand" :class="{ 'legend-container': true, dark: $vuetify.theme.dark }">
        <h1 :class="{ 'legend-main-title': true, dark: $vuetify.theme.dark }">Legend</h1>
        <div class="legend-content">
          <div style="display: flex; align-items: center; margin-bottom: 12px">
            <img src="@/assets/PlannedBurn_Button.svg" width="14px" height="14px" style="margin-right: 6.5px" />
            <span class="legend-prescribe-text"> PFIRS Data <span class="legend-prescribe-text addtional">(Prescribed Burns)</span> </span>
          </div>
          <h2 :class="{ 'legend-sub-title': true, dark: $vuetify.theme.dark }">Time since detection</h2>
          <div v-for="key in legendKeys" :key="key" class="legend-map-view-key">
            <div class="legend-map-view-key-color outer" :style="`background-color: ${key.borderColor};`">
              <div class="legend-map-view-key-color inner" :style="`background-color: ${key.color}`"></div>
            </div>

            <div :class="`legend-map-view-key-text`">{{ key.text }}</div>
          </div>
        </div>
      </section>
    </transition>

    <img v-if="$vuetify.theme.dark" class="legend-icon" src="@/assets/Legend_Dark.svg" width="36px" height="36px" @click="showLegendDetail" />
    <img v-else src="@/assets/Legend_Light.svg" class="legend-icon" width="36px" height="36px" @click="showLegendDetail" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      legendExpand: false,
      legendKeys: [
        {
          borderColor: '#F9423A73',
          color: '#F9423A',
          text: '0~3 Hours',
        },
        {
          borderColor: '#FFB80073',
          color: '#FFB800',
          text: '3~6 Hours',
        },
        {
          borderColor: '#384CFF73',
          color: '#384CFF',
          text: '6~24 Hours',
        },
        {
          borderColor: '#6D6D6D73',
          color: '#6D6D6D',
          text: 'Extra',
        },
      ],
    }
  },
  methods: {
    showLegendDetail() {
      this.legendExpand = !this.legendExpand
    },
  },
}
</script>

<style lang="scss" scoped>
.legend-container {
  border-radius: 8px;
  width: 188px;
  height: 192px;
  padding: 12px 0 0 16px;
  box-sizing: border-box;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  color: var(--f-text-black);
  background-color: #ffffff;
  &.dark {
    background-color: var(--f-bg-middle);
    color: #ffffffde;
  }
  .legend-content {
    margin-left: 4px;
  }
}

.legend-fade-enter-active {
  animation: fadeIn 0.2s ease-in;
}
.legend-fade-leave-active {
  animation: fadeOut 0.2s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(3px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeOut {
  from {
    transform: translateY(0px);
    opacity: 1;
  }
  to {
    transform: translateY(3px);
    opacity: 0;
  }
}

.legend-main-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 15px;
}

.legend-sub-title {
  font-weight: 600;
  font-size: 11px;
  line-height: 150%;
  margin-bottom: 5px;
}

.legend-map-view-key {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.legend-map-view-key-color {
  border-radius: 50%;

  &.inner {
    height: 7.8px;
    width: 7.8px;
  }
  &.outer {
    margin-right: 4px;
    height: 10px;
    width: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.legend-map-view-key-text {
  font-weight: 500;
  font-size: 10px;
  line-height: 170%;
}

.legend-prescribe-text {
  font-weight: 500;
  font-size: 10px;
  line-height: 170%;

  &.addtional {
    font-size: 9px;
  }
}

.legend-icon {
  cursor: pointer;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  box-sizing: content-box;
  margin-top: 5px;
}
</style>
